.userBadge {
  display: flex;
  cursor: pointer;
}

.name {
  font-size: 19px;
  font-weight: 500;
  color: #000;
  vertical-align: bottom;
}
.root {
  display: flex;
  align-items: flex-end;
}

.nameLoad {
  width: 62px;
  height: 17px;
  background-color: #e6e6e6;
  border-radius: 10px;
}

.placeholder {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #e6e6e6;
}

.avatar {
  vertical-align: bottom;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
}
