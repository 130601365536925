.root {
  position: relative;
  width: 72%;
  height: 40vh;
  margin: 0 auto;
  .contentEdit {
    width: 100%;
    position: absolute;
    margin: 80px 0px;

    .pass {
      display: flex;
      margin-bottom: 20px;

      .passLabel {
        display: flex;
        font-size: 19px;
        margin-right: 30px;
        margin-bottom: 12px;
        padding: 10px 0px;
      }

      input {
        display: block;
        text-align: center;
        border: none;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 10px 30px;

        &:focus {
          box-shadow: 0px 0px 25px 3px rgba(155, 154, 154, 0.2);
        }
      }
    }

    .editPassBtn {
      display: block;

      width: 330px;
      height: 45px;
      border: none;
      background: rgb(19, 18, 18);
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 auto;

      &:hover {
        color: #23d5ab;
        transition: 0.1s ease-in-out;
        opacity: 0.9;
      }
    }
  }
}

@media (max-width: 767px) {
  .root {
    position: relative;
    width: 100%;
    height: auto;
    .contentEdit {
      width: 95%;

      position: relative;
      padding: 20px 0;
      margin: 0 auto;

      .pass {
        display: flex;
        margin-bottom: 30px;

        .passLabel {
          display: block;
          font-size: 12px;
          margin-right: 11px;
          margin-bottom: 15px;
          padding: 5px 0;
        }

        input {
          display: block;
          text-align: center;
          border: none;
          font-size: 10px;
          width: 170px;
          margin-bottom: 21px;
          border-radius: 5px;
          padding: 5px 10px;

          &:focus {
            box-shadow: 0 0 10px rgba(155, 154, 154, 0.2);
          }
        }
      }
      .editPassBtn {
        display: block;
        width: 220px;
        height: 35px;
        border: none;
        font-size: 12px;
        background: rgb(19, 18, 18);
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px auto;

        &:hover {
          color: #23d5ab;
          transition: 0.1s ease-in-out;
          opacity: 0.9;
        }
      }
    }
  }
}
