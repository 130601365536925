* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  box-sizing: border-box;
  color: #181515;
}

html {
  background: rgb(71, 126, 189);
  height: 100vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Dancing Script', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding-bottom: 60px;
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgb(71, 126, 189) 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  width: 90%;
  min-height: 600px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 20px;
}

.loader {
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    width: 90%;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
