.root {
  width: 85%;
  height: 100%;
  margin: 0 auto;

  h2 {
    padding-top: 20px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .content {
    p {
      text-align: center;
      font-size: 17px;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 340px;
      height: 340px;
    }
  }
}

@media (max-width: 767px) {
  .root {
    .content {
      img {
        display: block;
        margin: 0 auto;
        width: 270px;
        height: 270px;
      }
    }
  }
}
