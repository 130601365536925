.root {
  position: fixed;
  top: 0;
  z-index: 150;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(11, 10, 10, 0.9);
  backdrop-filter: blur(2px);
  border-bottom: 2px groove #bdbcbc;

  a {
    img {
      width: 38px;
      height: 36px;

      &:active {
        transform: translateX(-1px);
      }
    }
  }

  img {
    padding: 7px;
    width: 38px;
    height: 38px;

    &:active {
      transform: translateY(1px);
    }
  }
}

.downContent {
  display: flex;
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 30px;
  border-radius: 5px;
  background-color: #f1f1f1;
  max-width: 220px;
  margin: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    font-size: 13px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: rgb(167, 240, 240);
      opacity: 0.9;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgb(214, 212, 212);
    }
  }
}
