.footer {
  width: 100%;
  height: 70px;
  margin-top: 20px;
  text-align: center;
  padding-top: 10px;

  nav {
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;
      color: rgb(27, 27, 27);
      margin-right: 10px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

      &:hover {
        color: #29efc1;
        transition: 0.2s;
        opacity: 0.9;
      }
    }
  }
}

@media (max-width: 767px) {

  .footer {
    nav {
      a {
        font-size: 12px;
      }
    }
  }
}
