.modal {
  height: 600px;
  background-color: #fff;

  .modalRoot {
    display: flex;
    flex-direction: row;

    .leftBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      height: 600px;
      width: 500px;
      flex: 1;

      .modalImage {
        width: 500px;
        max-height: 600px;
      }
    }

    .rightBlock {
      display: flex;
      width: 400px;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      .modalHeader {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #dbdbdb;
      }

      .modalComments {
        max-height: 600px;
        padding: 10px;
      }

      .footerModal {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-top: 1px solid #dbdbdb;
        padding-top: 10px;

        textarea {
          resize: none;
          border: none;
          display: block;
          width: 80%;
          height: 60px;
          border-radius: 3px;
          padding: 20px;
          font-size: 13px;
          margin-right: 5px;

          &::placeholder {
            color: #c7c6c6;
          }
        }

        button {
          background: lighten(#2ec4b6, 7%);
          height: 50px;
          padding: 1px 10px;
          margin-right: 5px;
          border: none;
          border-radius: 4px;
          appearance: none;
          color: #ffffff;

          &:hover {
            transition: 0.1s ease-in-out;
            background-color: #2ab0a3;
          }
        }
      }
    }
  }
}

.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.bodyOverflow {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .modal {
    width: 76%;
    height: 400px;
    .modalRoot {
      flex-direction: column;

      .leftBlock {
        width: 100%;
        .modalImage {
          width: 630px;
          height: 400px;
        }
      }

      .rightBlock {
        width: 100%;
        background-color: #fff;
      }
    }
  }

  .modalOverlay {
    top: -580px;
  }
}
@media (max-width: 695px) {
  .modal {
    width: 100%;
    .modalRoot {
      flex-direction: column;

      .leftBlock {
        width: 100%;
        .modalImage {
          width: 500px;
          height: 400px;
        }
      }

      .rightBlock {
        width: 100%;
        background-color: #fff;
      }
    }
  }

  .modalOverlay {
    top: -150px;
  }
}

@media (max-width: 547px) {
  .modal {
    width: 100%;
    .modalRoot {
      flex-direction: column;

      .leftBlock {
        width: 100%;
        .modalImage {
          width: 100%;
          height: 370px;
        }
      }

      .rightBlock {
        width: 100%;
        background-color: #fff;
      }
    }
  }

  .modalOverlay {
    top: -150px;
  }
}
