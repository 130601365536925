.hr-shelf {
  padding: 0;
  height: 4px;
  border-radius: 2px;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  animation: hr-shelf 5s ease infinite;
  background-size: 400% 400%;
}

@keyframes hr-shelf {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
