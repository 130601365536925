.container {
  display: flex;
  justify-content: space-between;
  width: 1220px;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  background-color: rgb(199, 245, 245);

  .leftBlock {
    display: flex;
    align-items: center;

  }
}
.rightBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search {
    background-color: #fff;
    padding: 18px 0px;
    width: 280px;
    margin-right: 40px;
    border: none;
    border-radius: 10px;
    block-size: 27px;
    text-align: center;

    &:focus {
      border: 1px solid #b9b8b8;
    }
  }

  .rightPath {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 22px;

    .userBadgeHeader {
      display: flex;
    }

    .iconsBlock {
      display: flex;
      margin-right: 30px;
      gap: 30px;

      a {
        width: 32px;
        height: 32px;
        font-size: 30px;
        opacity: 0.9;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.downContent {
  display: flex;
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 60px;
  border-radius: 5px;
  background-color: #f1f1f1;
  max-width: 220px;
  margin: 12px;
  // overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: rgb(167, 240, 240);
      opacity: 0.9;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgb(214, 212, 212);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    padding: 0 7px;
  }
  .header {
    .leftBlock {
      display: flex;
      justify-content: center;
      .logo {
        text-align: center;
        font-size: 23px;
      }
    }
    .rightBlock {
      .search {
        width: 115px;
        padding: 15px 4px;
      }
    }
  }
}

@media only screen and (max-width: 552px) {
  .container {
    padding: 0 7px;
    .header {
      .rightBlock {
        justify-content: center;
        .rightPath {
          flex-direction: column;
        }
      }
    }
    .search {
      margin-left: 7px;
      margin-right: 24px;
      padding: 2px 3px;
    }
  }
}
