.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container {
    width: 612px;
    min-height: auto;
    margin-top: 5px;
    margin-bottom: 40px;
    border: 1px solid #fff;
    border-radius: 4px;

    .errorUsers {
     display: flex;
     justify-content: center;
     padding: 10px 0;

     span {
      margin-left: 5px;
      font-size: 19px;
     }
    }

    .content {
      display: flex;
      justify-content: center;
      height: 100%;

      .avatar {
        height: 160px;
        width: 160px;
        margin-left: 10px;
        margin-top: 30px;
        margin-right: 35px;
        border-radius: 50%;
        box-shadow: 1px 1px 15px -5px black;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
        }
      }

      .rightBlock {
        max-width: 380px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 10px;

        .upBlock {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 12px;

          span {
            font-size: 29px;
            font-weight: 600;
            margin-left: 10px;
            margin-right: 20px;
          }

          .editBtn {
            background: #111;
            padding: 20px 25px 10px 25px;
            color: #fff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            font-family: inherit;
            padding-top: 7px;

            &:hover {
              opacity: 0.9;
            }
          }
        }

        .lowBlock {
          display: flex;
          margin-bottom: 10px;
          gap: 10px;

          .subscribed {
            display: flex;
            gap: 5px;
          }
          span {
            font-size: 17px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          }
        }

        .lowRow {
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .root {
    .container {
      width: 350px;
      height: auto;
      border: none;
      margin-top: 30px;
      margin-bottom: 40px;

      .content {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;

        .avatar {
          margin-top: 10px;
          height: 85px;
          width: 85px;
          margin-right: 18px;
        }

        .rightBlock {
          display: flex;
          flex-direction: column;
          max-width: 240px;
          margin-top: 0;
          margin-bottom: 0;
          height: auto;

          .upBlock {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 0;

            span {
              margin-bottom: 7px;
              margin-right: 10px;
              font-size: 21px;
            }

            img {
              position: relative;
              top: -1px;
              width: 20px;
              height: 20px;
            }

            .editBtn {
              background: #111;
              // padding: 10px 15px 10px 15px;
              color: #fff;
              border: none;
              cursor: pointer;
              border-radius: 5px;
              font-family: inherit;
              padding-top: 7px;

              &:hover {
                opacity: 0.9;
              }
            }
          }

          .lowBlock {
            .subscribed {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 0;
            }
            span {
              font-size: 11px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .lowContent {
      margin-top: 10px;
      margin-left: 12px;
      margin-bottom: 20px;
      font-size: 13px;
      .lowRow {
        font-weight: 600;
      }
    }
  }
}
