.nav {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  overflow: hidden;
  z-index: 150;
}

.active {
  filter: brightness(1.5);
}

.activeUser {
  width: 32px;
  height: 32px;
  background-color:#8a8989;
  border-radius: 50%;
}

.navWrapper {
  width: 100%;
  height: 70px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px ridge #bdbcbc;
  background-color: rgba(11, 10, 10, 0.9);
  backdrop-filter: blur(2px);

  a {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255);
    cursor: pointer;

    img {
      position: absolute;
      top: 10px;
    }
  }
}
