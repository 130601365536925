.userBlock {
  display: flex;
  justify-content: center;
  gap: 40px;

  @media only screen and (max-width: 767px) {
    gap: 0;
    display: flex;
  }

  .leftBlock {
    width: 45%;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .picturesSlide {
      position: relative;
      max-width: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 590px;
        border-radius: 5px;
        opacity: 0;
        animation-name: pictures-slide;
        animation-duration: 12s;
        transition: 0.5s ease-in-out;
        animation-iteration-count: infinite;
      }

      img:nth-child(1) {
        animation-delay: 0s;
      }
      img:nth-child(2) {
        animation-delay: 4s;
      }
      img:nth-child(3) {
        animation-delay: 8s;
      }
      img:nth-child(4) {
        animation-delay: 12s;
      }

      @keyframes pictures-slide {
        0% {
          opacity: 0;
        }
        11% {
          opacity: 1;
        }
        33% {
          opacity: 1;
        }
        44% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  .rightBlock {
    width: 375px;
    margin-top: 40px;
    text-align: center;
    box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.2);

    @media only screen and (max-width: 767px) {
      display: flex;
      width: 360px;
      margin-right: 0;
      margin-top: 70px;
    }

    .userLoginBlock {
      width: 100%;
      min-height: 500px;
      border-radius: 4px;
      background: transparent;

      @media only screen and (max-width: 767px) {

        width: 100%;
        height: auto;
      }



      .enterBlock {
        .iconVk {
          width: 22px;
          height: 22px;
          margin-right: 5px;
          vertical-align: text-bottom;
        }
        a {
          color: #1f1f21;
          &:hover {
            color: #535353;
          }
        }
        .enterBlockP {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
      .userLogo {
        width: 80%;
        margin: 30px 0;
      }

      .linkApplications {
        display: flex;
        justify-content: space-between;
        margin: 10px 20px;
        gap: 10px;

        img {
          max-width: 150px;
        }

        a:hover {
          opacity: 0.9;
        }
      }
    }
    .row {
      .formGroup {
        .btnForm {
          padding: 12px 120px;
          border: none;
          background: rgb(19, 18, 18);
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
          margin-top: 12px;
          margin-bottom: 30px;

          &:hover {
            color: #23d5ab;
            transition: 0.1s ease-in-out;
            opacity: 0.9;
          }
        }
      }
    }
  }
}

