.registrationBlock {
  width: 360px;
  min-height: 580px;
  font-size: 23px;
  margin: auto;
  border-radius: 4px;
  text-align: center;
  background: transparent;
  box-shadow: 0px 0px 71px 24px rgba(49, 49, 49, 0.2);

  .upBlock {
    .logo {
      width: 80%;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    p {
      width: 320px;
      margin: 0 auto;
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 18px;
      opacity: 0.8;
    }
  }

  .row {
    .formGroup {
      .btnForm {
        padding: 12px 100px;
        border: none;
        background: rgb(19, 18, 18);
        color: #fff;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 12px;
        margin-bottom: 30px;

        &:hover {
          color: #23d5ab;
          transition: 0.1s ease-in-out;
          opacity: 0.9;
        }
      }
    }

    .enterBlock {
      font-size: 20px;
      .iconVk {
        width: 22px;
        height: 22px;
        margin-right: 5px;
        vertical-align: text-bottom;
      }
      a {
        // font-size: 20px;
        color: #1f1f21;
        &:hover {
          color: #535353;
        }
      }

      .enter {
        a {
          margin-left: 3px;
        }
      }
      .enterBlockP {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .linkApplications {
      display: flex;
      justify-content: center;
      margin: 10px 20px;
      gap: 10px;

      img {
        max-width: 150px;
      }

      a:hover {
        opacity: 0.9;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
 .registrationBlock {
  margin-top: 60px;

  .upBlock {
    p {
      width: 250px;
      font-size: 15px;
    }
   }
 }

 
}