.container {
  display: flex;
  justify-content: center;
  height: auto;

  .leftBlock {
    width: 330px;
    height: 620px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #3b3e49;
    display: inline-block;
    font-size: 15px;
    vertical-align: top;

    header {
      padding: 30px 20px;

      input {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 50px 0 20px;
        background-color: #5e616a;
        border: none;
        border-radius: 3px;
        color: #fff;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
        background-repeat: no-repeat;
        background-position: 170px;
        background-size: 40px;

        &::placeholder {
          color: #fff;
        }
      }
    }
    ul {
      padding-left: 0;
      margin: 0;
      list-style-type: none;
      //   overflow-y: scroll;
      height: 690px;

      li {
        cursor: pointer;
        padding: 10px 0;

        &:hover {
          transition: 0.2s ease-in;
          background-color: #5e616a;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-left: 20px;
          margin-right: 8px;
        }
        div {
          display: inline-block;
          vertical-align: top;
          margin-top: 12px;
        }
        h2 {
          font-size: 14px;
          color: #fff;
          font-weight: normal;
          margin-bottom: 5px;
        }
        h3 {
          font-size: 12px;
          color: #7e818a;
          font-weight: normal;
        }
      }
    }
  }
  .rightBlock {
    width: 680px;
    height: 620px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #eff3f7;
    display: inline-block;
    font-size: 15px;
    vertical-align: top;

    header {
      display: flex;
      height: 110px;
      padding: 30px 20px 30px 40px;

      img {
        width: 50px;
        height: 50px;

        &:first-child {
          border-radius: 50%;
        }

        &:last-child {
          width: 24px;
          margin-top: 8px;
        }
      }
      .headerText {
        margin-left: 10px;
        margin-right: 145px;

        h2 {
          font-size: 16px;
          margin-bottom: 5px;
        }

        h3 {
          font-size: 14px;
          font-weight: normal;
          color: #7e818a;
        }
      }
    }

    .chat {
      padding-left: 0;
      margin: 0;
      list-style-type: none;
      overflow-y: scroll;
      height: 335px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;

      li {
        padding: 10px 30px;

        h2,
        h3 {
          margin-right: 5px;
          display: inline-block;
          font-size: 13px;
          font-weight: normal;
        }
        h3 {
          color: #bbb;
        }
      }
    }

    .head {
      margin-bottom: 5px;
    }
    .message {
      padding: 20px;
      color: #fff;
      line-height: 25px;
      max-width: 90%;
      display: inline-block;
      text-align: left;
      border-radius: 5px;
    }
    .me {
      text-align: right;
    }

    .triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
    }
    .triangleYou {
      border-color: transparent transparent lighten(#2ec4b6, 7%) transparent;
      margin-left: 15px;
    }
    .triangleMe {
      border-color: transparent transparent lighten(#60c0fc, 7%) transparent;
      margin-left: 430px;
    }

    .footer {
      height: 155px;
      padding: 20px 30px 10px 20px;

      textarea {
        resize: none;
        border: none;
        display: block;
        width: 100%;
        height: 80px;
        border-radius: 3px;
        padding: 20px;
        font-size: 13px;
        margin-bottom: 13px;

        &::placeholder {
          color: #c7c6c6;
        }
      }

      img {
        height: 30px;
        cursor: pointer;
      }

      a {
        background: lighten(#2ec4b6, 7%);
        float: right;
        justify-content: flex-end;
        margin-top: 5px;
        padding: 8px 24px;
        text-decoration: none;
        border-radius: 4px;
        appearance: none;
        // text-transform: uppercase;
        // font-weight: bold;
        color: #ffffff;
        vertical-align: top;
        transition: all 0.2s ease;

        &:hover {
          background: lighten(#2eaa9e, 7%);
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
    color: #58b666;
  }
  .green {
    background-color: lighten(#2ec4b6, 7%);
  }
  .orange {
    background-color: lighten(#ef6f00, 7%);
  }
  .blue {
    background-color: lighten(#60c0fc, 7%);
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 40px;

    .leftBlock {
      width: 100%;
      height: auto;
      border-radius: 0;

      header {
        input {
          display: block;
          height: 0;
          padding: 25px 90px;
          margin: 0 auto;
          border-radius: 20px;

          &::placeholder {
            text-align: center;
          }
        }
      }

      ul {
        height: auto;

        li {
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .rightBlock {
      width: 100%;
      height: auto;
      border-radius: 0;

      header {
        display: flex;
        height: auto;
        padding: 20px;
      }
      .footer {
        margin-bottom: 10px;
      }
    }
  }
}
